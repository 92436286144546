import * as THREE from 'three';
import { EventDispatcher } from "three";
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
import { GlitchPass } from 'three/addons/postprocessing/GlitchPass.js';
import { Hologram } from './hologram';
import { Rig } from './rig';
import { Global } from './global';
import { ArcadeJoystick } from './joystick';
import { ArcadeScreen } from './screen';
import _ from 'lodash';
import { useArcadeStore } from '@/store';
import { Beamer } from './beamer';
import { Boombox } from './boombox';

export class World  extends EventDispatcher {    
    
    isAlive = true;
    sceneLoaded = false;
    ENTIRE_SCENE = 0;
    BLOOM_SCENE = 1;

    screen_material = new THREE.MeshBasicMaterial();

    updatables = [];

    holo_point = new THREE.Vector3(0, 0, 0);

    light_intensity_target = 1.2;

    constructor(){
        super();
        
        this.store = useArcadeStore();

        const bloomLayer = new THREE.Layers();
        bloomLayer.set( this.BLOOM_SCENE );

        const params = {
            exposure: 1.0,
            bloomStrength: .7,
            bloomThreshold: .95,
            bloomRadius: .2
        };        

        const container = document.getElementById("interactive-scene");
        this.container = container;

        const scene = new THREE.Scene();
        scene.background = new THREE.Color('black');
        this.scene = scene;
 
        const camera = new THREE.PerspectiveCamera(35, container.clientWidth / container.clientHeight, 0.1, 1000);

        const rig = new Rig(camera);
        this.rig = rig;
        this.updatables.push(rig);

        rig.pivot.position.z = 10;
        rig.pivot.position.y = 2.0;

        this.scene.add(rig.pivot);        
        this.camera = camera;
        Global.camera = camera;
 
        const renderer = new THREE.WebGLRenderer({antialias: true});
        renderer.setSize(container.clientWidth, container.clientHeight);
        renderer.setPixelRatio(window.devicePixelRatio);                
        //renderer.physicallyCorrectLights = true;        
        this.renderer = renderer;

        this.arcade_machine = new ArcadeScreen(this.renderer);

        const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
        bloomPass.threshold = params.bloomThreshold;
        bloomPass.strength = params.bloomStrength;
        bloomPass.radius = params.bloomRadius;

        
        const renderScene = new RenderPass( this.scene, this.camera );
        const composer = new EffectComposer( renderer );
        
        composer.addPass( renderScene );
        composer.addPass( bloomPass );        

        

        this.composer = composer;
        

        
        this.clock = new THREE.Clock();

        container.append(renderer.domElement);


        window.addEventListener('resize', this.resize);
        
        this.createGround();        
        this.createArcadeMachine();    
        this.render();
        
    }


    createGround = () => {
        const texture = new THREE.TextureLoader().load( './textures/wood_cabinet_worn_long_diff_1k.jpg', this.store.tickLoadCount);
        const displacement = new THREE.TextureLoader().load( './textures/wood_cabinet_worn_long_disp_1k.jpg', this.store.tickLoadCount );
        const normal  = new THREE.TextureLoader().load( './textures/wood_cabinet_worn_long_nor_gl_1k.jpg', this.store.tickLoadCount); 
        const roughness  = new THREE.TextureLoader().load( './textures/wood_cabinet_worn_long_rough_1k.jpg', this.store.tickLoadCount ); 
        texture.wrapS = texture.wrapT = THREE.RepeatWrapping;
        texture.repeat.set(6.0, 6.0);
        displacement.wrapS = displacement.wrapT = THREE.RepeatWrapping;
        displacement.repeat.set(6.0, 6.0);
        normal.wrapS = normal.wrapT = THREE.RepeatWrapping;
        normal.repeat.set(6.0, 6.0);
        roughness.wrapS = roughness.wrapT = THREE.RepeatWrapping;
        roughness.repeat.set(6.0, 6.0);
        const geometry = new THREE.PlaneGeometry( 20, 20);
        const material = new THREE.MeshStandardMaterial( {
                color: 0xffffff, 
                map: texture, 
                /*
                displacementMap: displacement, 
                displacementScale: 2.0,
                */
                normalMap: normal, 
                normalScale: new THREE.Vector2(1.5, 1.5),
                roughnessMap: roughness
            });
        const plane = new THREE.Mesh( geometry, material );
        plane.rotation.x = - Math.PI / 2.0;
        this.scene.add( plane );
        this.ground = plane;        
    }
    
    createLight = () => {
        const spotLight = new THREE.SpotLight( 0xffffff );
        
        
        

        spotLight.intensity = 0.0;
        
        spotLight.angle = Math.PI / 16.0;
        spotLight.distance = 1000.0;
        spotLight.penumbra = 1.0;

        /*
        spotLight.castShadow = true;

        spotLight.shadow.mapSize.width = 1024;
        spotLight.shadow.mapSize.height = 1024;

        spotLight.shadow.camera.near = 20;
        spotLight.shadow.camera.far = 4000;
        spotLight.shadow.camera.fov = 5;
        */
        this.scene.add( spotLight );
        //this.scene.add( spotLight.target );
        spotLight.position.set( 0, 10, 10 );        
        spotLight.target.position.set(0,1,-1);
        spotLight.target.updateMatrixWorld();
        this.spot_light = spotLight;

        const backSpotLight = new THREE.SpotLight(0x4400FF);
        backSpotLight.position.set(4, 4, -3);
        backSpotLight.penumbra = 1.0;
        backSpotLight.angle = 20.0 * (Math.PI / 180);        
        backSpotLight.intensity = 0;
        backSpotLight.target.position.set(0,0,0);
        //backSpotLight.target.updateMatrixWorld();        
        this.scene.add(backSpotLight);
        this.scene.add(backSpotLight.target);
        this.back_spot_light = backSpotLight;
        
        
        const doorLight = new THREE.PointLight(0x00FFFF, 2.0, 6.0);
        doorLight.position.set(0, 3.0, -4.0);
        this.scene.add(doorLight);
        this.door_light = doorLight;
        /*
        const light = new THREE.AmbientLight( 0x808080 ); // soft white light
        this.scene.add( light );

        const fog = new THREE.Fog(0x000000, 5.0, 8.0);
        this.scene.fog = fog;
        */
    }

    createArcadeMachine = () => {
        
        const loader = new GLTFLoader();
        loader.load('./models/arcade.gltf', (gltf) => {            
            //const video = document.getElementById( 'video' );
            //const texture = new THREE.VideoTexture( video );

            gltf.scene.traverse((child) => {
                if(child.isMesh && child.name == "Screen"){       

                                                                                
                    this.screen_texture = this.arcade_machine.render_target.texture;

                    this.screen_material = child.material;                        
                    //child.material.map = this.arcade_machine.render_target.texture;
                    child.material.map = this.screen_texture;
                    
                    this.screen = child;                    
                }

                if(child.name == "HoloTarget"){
                    this.hologram = new Hologram(this.scene, child);
                    this.holo_point.copy(child.position);
                    this.holo_point.y = 0;
                    this.updatables.push(this.hologram);
                }

                if(child.name == "CameraTarget"){                    
                    this.camera_target = child;
                }

                if(child.name == "Door"){
                    this.door = child;
                }

                if(child.name == "VisitCard"){
                    this.visit_card = child;
                }

                if(child.name == "CameraVisitCardTarget"){
                    this.visit_card_target = child;
                }

                if(child.name == "Ground"){
                    this.newGround = child;
                    this.newGround.visible = false;                    
                }
            });
            
            this.beamer = new Beamer(this.scene, gltf.scene);
            this.updatables.push(this.beamer);

            this.boombox = new Boombox(this.scene, gltf.scene);
            this.updatables.push(this.boombox);
            
            this.joystick = new ArcadeJoystick(gltf.scene);
            this.updatables.push(this.joystick);

            this.bootloader_position = new THREE.Vector3(0, 1.5, 1.0);

            this.scene.add(gltf.scene);
            
            this.createLight();

            this.sceneLoaded = true
            //this.zoomToMachine();
            this.dispatchEvent({type:'loading_done'});            
        });
    }    

    zoomToVisitCard = () => {        
        

        new TWEEN.Tween(this.rig.pivot.position).to({
            x:this.visit_card_target.position.x,
            y:this.visit_card_target.position.y,
            z:this.visit_card_target.position.z
        }, 2500)
        .easing(TWEEN.Easing.Cubic.Out)        
        .start();


        new TWEEN.Tween(this.rig.pivot.rotation).to({
            x:this.visit_card_target.rotation.x,
            y:this.visit_card_target.rotation.y,
            z:this.visit_card_target.rotation.z
        }, 2500)
        .easing(TWEEN.Easing.Cubic.Out)        
        .start();
    }

    skipIntro = () => {
        this.door.rotation.y = Math.PI / 1.8;
        this.spot_light.intensity = this.light_intensity_target;
        this.back_spot_light.intensity = this.light_intensity_target;
        this.rig.pivot.position.copy(this.camera_target.position);
        this.rig.pivot.rotation.copy(this.camera_target.rotation);
    }

    zoomToMachine = () => {        
        new TWEEN.Tween(this.door.rotation).to({
            y: Math.PI / 1.8
        }, 1750)                
        .easing(TWEEN.Easing.Back.Out)        
        .delay(250)
        .start();

        new TWEEN.Tween(this.spot_light).to({
            intensity: this.light_intensity_target
        }, 3000)
        .delay(2000)
        .easing(TWEEN.Easing.Bounce.Out)        
        .start();

        new TWEEN.Tween(this.back_spot_light).to({
            intensity: this.light_intensity_target
        }, 3000)
        .delay(2000)
        .easing(TWEEN.Easing.Bounce.Out)        
        .start();


        new TWEEN.Tween(this.rig.pivot.position).to({
            x:this.camera_target.position.x,
            y:this.camera_target.position.y,
            z:this.camera_target.position.z
        }, 2500)
        .easing(TWEEN.Easing.Cubic.Out)
        .delay(4000)
        .start();


        new TWEEN.Tween(this.rig.pivot.rotation).to({
            x:this.camera_target.rotation.x,
            y:this.camera_target.rotation.y,
            z:this.camera_target.rotation.z
        }, 2500)
        .easing(TWEEN.Easing.Cubic.Out)
        .delay(1500)
        .start();

        setTimeout(() => {
            this.dispatchEvent({type:'zoom_done', message:null});            
        }, 4000)
        /*
        setTimeout(() => {
            this.zoomToVisitCard();
        }, 8000);
        */
    }

    resize = (event) => {
        //_.throttle(() => {
            let width = window.innerWidth;
            let height = window.innerHeight;            
            this.camera.aspect = width / height;
            this.camera.updateProjectionMatrix();            
            this.composer.setSize(width, height);
            this.renderer.setSize(width, height);        
            this.renderer.setPixelRatio(window.devicePixelRatio);
        //},
        //500,
        //{trailing: true}
        //);
        
    }

    render = () => {
        let delta = this.clock.getDelta();        

        if(this.isAlive){
            requestAnimationFrame(this.render);
        }
            
        

        
        if (this.sceneLoaded){
            this.updatables.forEach(updatable => {
                updatable.update(delta);
            });            
            
            this.arcade_machine.update(delta, this.joystick);
            this.arcade_machine.render(); 
            //this.screen_texture = this.arcade_machine.composer.readBuffer.texture;                        
            this.screen_texture.needsUpdate = true;
            //this.screen_material.map = this.arcade_machine.composer.writeBuffer.texture;
            //console.log(this.arcade_machine.composer.writeBuffer.texture);
            //this.screen_material.needsUpdate = true;
    
        }
            
        this.composer.render();                
        TWEEN.update();   

        if(this.beamer){
            this.store.project_position.update(this.beamer.beamer_object.position, this.camera, this.renderer);
        }
        
        if(this.visit_card){
            this.store.visit_card_position.update(this.visit_card.position, this.camera, this.renderer);
        }

        if(this.bootloader_position){
            this.store.bootloader_position.update(this.bootloader_position, this.camera, this.renderer);
        }

        if(this.boombox){
            this.store.boombox_position.update(this.boombox.origin.position, this.camera, this.renderer);
        }
    }
    

    switchGround = () => {
        if(this.ground.visible)       {
            this.newGround.visible = true;
            this.ground.visible = false;
        } else {
            this.newGround.visible = false;
            this.ground.visible = true;
        }
    }
}