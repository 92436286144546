<template>
    <div id="joystick-container" class="panel" v-if="isMobile">
        <div id="joystick-thumb" :style="thumbStyle"></div>
    </div>


</template>
<script setup>
import * as THREE from 'three';
import { onMounted, ref } from 'vue';
import { isMobile } from 'mobile-device-detect';

const touchStart = new THREE.Vector2();
const touchMove = new THREE.Vector2();
const touchEnd = new THREE.Vector2();

const thumbPosition = new THREE.Vector2();
const thumbDistance = 16;
const thumbDisplayPosition = new THREE.Vector2();


const thumbStyle = ref({
    transform: "translate(0', 0)"
});

function onTouchMove(event){
    thumbPosition.set(event.touches[0].clientX, event.touches[0].clientY);
    thumbPosition.sub(touchStart);

    thumbDisplayPosition.copy(thumbPosition);
    
    if(thumbPosition.length() > 1.7){
        thumbPosition.normalize();
    }
    
    
    updateThumbStyle();
}

function onTouchStart(event){
    touchStart.set(event.touches[0].clientX, event.touches[0].clientY);
}

function onTouchEnd(event){
    thumbPosition.set(0,0);
    updateThumbStyle();
}

function updateThumbStyle(){    
    thumbStyle.value.transform = "translate("+ Math.round(thumbPosition.x * thumbDistance) + "px," + Math.round(thumbPosition.y * thumbDistance) + "px)";
}

onMounted(() => {
    if(!isMobile){
        return;
    }
    const joystickThumb = document.getElementById("joystick-thumb");
    joystickThumb.addEventListener('touchstart', onTouchStart);
    joystickThumb.addEventListener('touchmove', onTouchMove);
    joystickThumb.addEventListener('touchend', onTouchEnd);
});

//window.addEventListener('touchmove', onTouchMove);
</script>
<style lang="scss">

#joystick-container{
    border-radius: 32px;
    width: 32px;
    height: 32px;
    position: fixed;
    bottom: 16px;
    left: 16px;
}

#joystick-thumb{
    
    transform-origin: center center;
    //transform: translate(16px, 2px);
    border-radius: 32px;
    width: 32px;
    height: 32px;
    background: white;
}

</style>