<template>
    <div id="interface-overlay">
        <div id="interface-start" class="panel" v-if="store.state == 'init'">
            <p>
                The Project Room
            </p>
            <progress-bar :value="store.loading_value" v-if="store.loading"></progress-bar>
            <button @click="enterRoom" v-if="!store.loading">Enter</button>
        </div>
        <InterfaceProject/>
        <InterfaceCartridges />
        <InterfaceVisitCard />
        <interface-boombox/>
        <TouchControls />
        <!--
        <div id="debug-text" class="panel">
            {{ store.orientation }}    
            {{ store.boombox_position }}
        </div>
        -->
    </div>
</template>

<script setup>
    import { defineEmits } from 'vue';
    import InterfaceCartridges from './InterfaceCartridges.vue';    
    import InterfaceProject from './InterfaceProject.vue';
    import InterfaceVisitCard from './InterfaceVisitCard.vue';
    import InterfaceBoombox from './InterfaceBoombox.vue';
    import TouchControls from './TouchControls.vue';    
    import ProgressBar from './ProgressBar.vue';
    import { useArcadeStore } from '@/store/index';

    const emit = defineEmits(['interaction']);
    
    const store = useArcadeStore();    
   
   /*    
    setInterval(() => {
        store.loading_value += 0.5;
    }, 1000);
    */

    function enterRoom(){
        //store.state = "skip";
        store.state = 'enter';
        store.enterRoom();
    }
</script>
<style lang="scss">

    #interface-overlay{
        position:fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;        
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
    }

    #interface-start{
        top: 8%;
        position:relative;
    }

    #debug-text{
        position:absolute;
        top:0;
        transform:scale(.5);
    }
    
</style>