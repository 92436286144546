import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { GameScene } from './commons/game_scene';
import { text } from '@fortawesome/fontawesome-svg-core';

export class InvaderCartridge {

    gameover = false;

    world_boundaries = new THREE.Vector2(16.0, 10.0);

    time = 0;
    tick = 0;

    fire_rate = 1.0;

    fire_time = 0;

    fire_radius = 1.2;

    last_fire_time = 0;

    missiles = [];
    invaders = [];

    invaders_count = 24;

    invaders_move_rate = .5;
    invaders_move_time = 0;

    invaders_move_tick = 0;

    invaders_direction = 1.0;

    invaders_bounce = false;

    scene_color = new THREE.Color(.2, .2, .2);

    scenes = {};

    sprite_textures = [];

   constructor(){

       const camera = new THREE.OrthographicCamera(40.0 / -2, 40.0 / 2, 30.0 / 2, 30.0 / -2, 1, 1000);
       camera.position.z = 30;
       this.camera = camera;

       const game_scene = new THREE.Scene();
       this.game_scene = game_scene;
       
       this.game_scene.background = new THREE.Color(.2, .2, .2);       
       this.origin = new THREE.Object3D();
       this.game_scene.add(this.origin);

       this.create_start_scene();
       this.create_gameover_scene();
       this.create_win_scene();

        this.scene = this.start_scene;


        this.game_texture = new THREE.TextureLoader().load( "textures/invaders.png" );
        this.game_texture.repeat.set(.25, .25);

       const loader = new GLTFLoader();
        loader.load('./models/invaders.gltf', (gltf) => {
            this.models = gltf.scene;
            this.initialize();
        });
       
   }

   initialize = () => {
        this.gameover = false;
        this.origin.clear();
        this.invaders_move_rate = 1.0;
        this.missiles = [];
        this.invaders = [];        

        const geometry = new THREE.BoxGeometry( 1, 1, 1 );
        const material = new THREE.MeshBasicMaterial( {color: 0x00ff00} );
        const cube = new THREE.Mesh( geometry, material );
        this.hero = this.create_sprite_from_offset(0xaaff00, 0, .25);        
        this.origin.add( this.hero );
        this.hero.position.y = -10;
        
        const monster_pivot = new THREE.Object3D();
        this.origin.add(monster_pivot);
        this.invaders_container = monster_pivot;
        
        this.generate_invaders();        
        this.scene = this.start_scene;
        //this.scene = this.gameover_scene;
   }

   update = (delta, joystick) => {
        this.time += delta;
        this.tick ++;

        if(this.scene != null){
            this.scene.background = this.scene_color;   
        }

        if(this.scene == this.start_scene){
            this.update_start(delta, joystick);
        } else if(this.scene == this.game_scene){
            this.update_game(delta, joystick);
        } else if(this.scene == this.win_scene) {
            this.update_win(delta, joystick);
        }

        
   }

   update_start = (delta, joystick) => {
        if(joystick.button1.pressed){
            this.scene = this.game_scene;
        }

        this.start_text.material.opacity = Math.round(this.time) % 2 == 0 ? 1.0 : 0;        
   }

   update_win = (delta, joystick) => {        
        this.win_text.material.opacity = Math.round(this.time) % 2 == 0 ? 1.0 : 0;        
   }

   update_game = (delta, joystick) => {
        this.hero.rotation.y += 0.1;

        this.hero.position.x += joystick.move_vector.x * delta * 8.0;        

        if(this.hero.position.x > this.world_boundaries.x){
            this.hero.position.x = - this.world_boundaries.x;
        }

        if(this.hero.position.x < - this.world_boundaries.x){
            this.hero.position.x = this.world_boundaries.x;
        }


        if(joystick.button1.pressed){
            this.fire(delta);
        }


        this.move_invaders(delta);
        this.update_projectiles(delta);        
        this.check_collisions();
   }

   insert = () => {

   }

   remove = () => {
        //this.game_scene.remove(this.origin);
    }

    fire = (delta) => {
        

        if (this.fire_time >= this.fire_rate){
            this.fire_time = 0;            
        }

        if(this.fire_time == 0 || this.time - this.last_fire_time >= this.fire_rate){
            this.fire_time = 0;
            this.last_fire_time = this.time;            
            const geometry = new THREE.BoxGeometry( 1, 1, 1 );            
            const material = new THREE.MeshBasicMaterial( {color: 0x00ffff} );
            const missile = this.create_sprite_from_offset(0x00ffff, .25, .25);
            missile.position.copy(this.hero.position);
            this.origin.add(missile);
            this.missiles.push(missile);
        }

        this.fire_time += delta;
   }

   flash = () => {
        new TWEEN.Tween(this.scene_color)
        .to(new THREE.Color('white'), 20)
        .start();

        new TWEEN.Tween(this.scene_color)
        .to(new THREE.Color(.2, .2, .2), 100)
        .delay(100)
        .start();
   }

   check_collisions = () => {
    let direction_switch = false;
    let invader_position = new THREE.Vector3(0,0,0);
        this.invaders.forEach((invader, i_index, i_object) => {
            invader.getWorldPosition(invader_position);

            this.missiles.forEach((missile, m_index, m_object) => {                            
                if(missile.position.distanceTo(invader_position) < this.fire_radius){
                    i_object.splice(i_index, 1);
                    m_object.splice(m_index, 1);
                    this.origin.remove(missile);
                    this.invaders_container.remove(invader);
                    this.flash();
                    if(i_object.length == 0){
                        this.do_win();
                        return;
                    }
                }
            });

            if(!direction_switch){
                if(invader_position.x > this.world_boundaries.x && this.invaders_direction > 0){
                    direction_switch = true;
                    this.invaders_direction *= -1;
                    this.invaders_bounce = true;
                } else if(invader_position.x < -this.world_boundaries.x && this.invaders_direction < 0) {
                    direction_switch = true;
                    this.invaders_direction *= -1;
                    this.invaders_bounce = true;
                }
                
            }
        });
   }

   update_projectiles = (delta) => {
        this.missiles.forEach(missile => {
            missile.position.y += 8.0 * delta;
        });
   }

   create_sprite_from_offset = (color, x,y) => {
        let texture = this.game_texture.clone();
        texture.offset.set(x,y);
        const pivot = new THREE.Object3D();
        const material = new THREE.SpriteMaterial({color: color, map: texture, sizeAttenuation: true});
        const sprite = new THREE.Sprite(material);
        pivot.add(sprite); 
        sprite.scale.set(4.0, 4.0, 4.0);
        this.sprite_textures.push(texture);
        texture.primary_offset = new THREE.Vector2(x,y);
        texture.secondary_offset = new THREE.Vector2(x,y - .25);
        return pivot;
   }

   generate_invaders = () => {
       let invader_model;
        this.models.traverse((child) => {
            invader_model = child;
        });
       
        let offset_x = .25;

        for(var i = 1; i <= this.invaders_count; i ++){
            let col = (i % 6);
            let row = Math.ceil(i / 6);

        
            if(row > 2){
            
                offset_x = 0;
            }   
        
            const invader = this.create_sprite_from_offset(0x00ff00, offset_x, .75);                
        
            invader.position.x = col * 4.0 - 8.0;
            invader.position.y = + 1.0 + row * 3.0;

            this.invaders_container.add(invader);
            this.invaders.push(invader);
        }
   }

   move_invaders = (delta) => {
        this.invaders_move_time += delta;
        if(this.invaders_move_time >= this.invaders_move_rate){
            this.invaders_move_time = 0;
            this.invaders_move_tick ++;
            this.invaders_container.position.x += this.invaders_direction;
            if(this.invaders_bounce == true){
                this.bounce_invaders();
            }
            
            if(this.invaders_move_tick % 2 == 0){
                this.sprite_textures.forEach(tex => {
                    tex.offset.copy(tex.primary_offset);
                    tex.needUpdate = true;
                });
            } else {
                this.sprite_textures.forEach(tex => {
                    tex.offset.copy(tex.secondary_offset);
                    tex.needUpdate = true;
                });
            }

        }
        
        let invader_position = new THREE.Vector3(0,0,0);
        this.invaders.forEach((item, index, object) => {
            item.getWorldPosition(invader_position);
            if(invader_position.y <= this.hero.position.y){
                this.do_gameover ();
            }
        });
   }

   bounce_invaders = () =>{
        this.invaders_bounce = false;
        this.invaders_container.position.y -= 1;
        this.invaders_move_rate /= 1.25;
   }
   
   do_win = () => {
       this.scene = this.win_scene;

       setTimeout(() => {
        this.do_reset();
       }, 3000);
   }

   do_gameover = () => {
       if(this.gameover)
       {
           return;
       }

       this.gameover = true;
       this.scene = this.gameover_scene;       
       this.flash();

       setTimeout(() => {
        this.do_reset();
       }, 3000);
   }

   do_reset = () => {
       this.initialize();
   }

   generate_sprite = (col, row) => {
        let texture = new THREE.TextureLoader().load( "/textures/texts.png" );
        texture.repeat.set(.25, .25);
        texture.offset.set(.25 * col, .25 * row);        
        
        
        const text_material = new THREE.SpriteMaterial({color: 0x00ff00, map: texture, sizeAttenuation: false});
        const text = new THREE.Sprite(text_material);
        let scale = 48.0;
        text.scale.set(scale, scale, scale);

        return text;
   }

   

   create_start_scene = () => {
        
        const start_scene = new THREE.Scene();
                
        const start_text = this.generate_sprite(0, 0);
        start_scene.add(start_text);

        
        this.start_text = start_text;
        this.start_scene = start_scene;
   }

   create_gameover_scene = () => {
        const gameover_scene = new THREE.Scene(); 
        const gameover_text = this.generate_sprite(2, 0);
        gameover_scene.add(gameover_text);
        
        

                
        this.gameover_scene = gameover_scene;
   }

   create_win_scene = () => {
    const win_scene = new THREE.Scene();
    const win_text = this.generate_sprite(1, 0);
    win_scene.add(win_text);
    this.win_text = win_text;
    this.win_scene = win_scene;
   }
       
}
