import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { EventDispatcher } from "three";

export class Cartridge extends EventDispatcher {
    scene = new THREE.Scene;
    scene_color = new THREE.Color('black');

    constructor(){
        super();    
    }

    initialize = () => {
        
    }

    update = (delta, joystick) => {

    }

    insert(){
        
    }

    remove(){

    }

    flash = () => {
        new TWEEN.Tween(this.scene_color)
        .to(new THREE.Color('white'), 20)
        .start();

        new TWEEN.Tween(this.scene_color)
        .to(new THREE.Color(.2, .2, .2), 100)
        .delay(100)
        .start();
   }
}