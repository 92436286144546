<template>
    <eye-folder :style="store.bootloader_position.style">
    <div id="interface-cartridges" class="panel">
        <p class="title">Bootloader</p>
        <div class="cartridge-choices">
            <button v-for="(item, index) in cartridges" :key="index" @click="setCartridge(index)" :disabled="isCurrent(index)">
                {{ item }}
            </button>
            <!--<button @click="setCartridge(0)">Projects</button>
            <button @click="setCartridge(1)">Invader</button>
            -->
        </div>
    </div>
    </eye-folder>
</template>
<script setup>
import { defineEmits } from 'vue';
import { useArcadeStore } from '@/store/index';
import EyeFolder from '@/components/EyeFolder.vue';

const cartridges = ["Projects", "Invaders", "Snake"];

const store = useArcadeStore();

function isCurrent(index){    
    return index == store.cartridge;
}

function setCartridge(cartridge_index){
    store.cartridge = cartridge_index;
    document.getElementById("interactive-scene").focus();
}

</script>
<style lang="scss">
    #interface-cartridges{
       position: absolute;        
       right: 16px;       
    }

    .cartridge-choices{
        display:flex;
        flex-direction: column;
        justify-content: space-between;
        button {
            margin-top:8px;
        }
    }
</style>