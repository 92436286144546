import { World } from './world';
import { EventDispatcher } from "three";
import { useArcadeStore } from '@/store';

export class Arcade extends EventDispatcher {

    constructor(){
        super();
        this.store = useArcadeStore();
        this.world = new World();        
    }    

    kill = () => {
        this.world.isAlive = false;
    }
    
    changeState(state){
        switch(state){
            case "skip":
                if(this.world.sceneLoaded){
                    this.world.skipIntro();
                } else {
                    //console.log("WAIT FOR LOADING");
                    this.world.addEventListener('loading_done', () => {
                        this.store.tickLoadCount();
                        this.world.skipIntro();
                    });
                }
                
                break;
            case "enter":
                this.world.zoomToMachine();
                break;
        }
    }

    
}