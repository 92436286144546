import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { ParticleSystem } from './commons/particle_system';

export class Hologram {
    time = 0;
    base_height = 0;
    scale = 0;    

    constructor(scene, holo_target){
        this.scene = scene;
        this.initialize(holo_target)        
    }    

    initialize = (holo_target) => {
        this.holo_target = holo_target;
        this.base_height = this.holo_target.position.y;
        const holoLight = new THREE.PointLight(0x44AAFF, 0.0, 3.0);
        holoLight.position.copy(this.holo_target.position);        
        this.holo_light = holoLight;
        this.scene.add(holoLight);

        var holoMaterial = new THREE.MeshBasicMaterial({wireframe: true, color: 0xAADDFF});
        this.holo_target.children.forEach(item => {
            
            
            item.material = holoMaterial;
            if(item.children.length > 0){
                item.children.forEach(child => {
                    child.material = holoMaterial;
                });
            }
        });

        /*
        this.dust = new ParticleSystem(100);
        this.dust.mesh.position.copy(this.holo_target.position);

        this.dust.move = (index, time, delta, emitter) => {
            

        };
        */ 
        //this.scene.add(this.dust.mesh);

        this.show();
    }

    update = (delta) => {
        this.time += delta;
        this.holo_target.rotation.y += delta;
        this.holo_target.rotation.x = Math.cos(this.time) * (Math.PI / 36);
        this.holo_target.position.y = this.base_height + Math.sin(this.time) * .15;
        //this.scale = 1.0 + Math.cos(this.time) * .1;
        this.holo_target.scale.set(this.scale, this.scale, this.scale);
        //this.dust.update(delta);
    }

    show = () => {
        let delay = 5000;
        
        let animation_time = 750;

        new TWEEN.Tween(this.holo_light)
        .to({intensity:8.0}, animation_time)
        .easing(TWEEN.Easing.Exponential.Out)
        .delay(delay)
        .start();

        new TWEEN.Tween(this)
        .to({scale:1.0}, animation_time)
        .easing(TWEEN.Easing.Exponential.Out)
        .delay(delay)
        .start();
    }

    hide = () => {
        new TWEEN.Tween(this.holo_light)
        .to({intensity:0.0}, 250)
        .easing(TWEEN.Easing.Exponential.Out)
        .start();

        new TWEEN.Tween(this)
        .to({scale:0.0}, 250)
        .easing(TWEEN.Easing.Exponential.Out)        
        .start();
    }
}