<template>   
    <eye-folder :style="store.visit_card_position.style">
        <div class="panel" id="interface-visit-card">
        <p>Pierre-Igor Berthet</p>
        <p>
            <ul>
            <li>
                <font-awesome-icon icon="fa-solid fa-envelope"/>&nbsp;
                <a href="mailto:{{ email }}">{{ email }}</a>&nbsp;
                <button class="mini-rounded" @click="copyEmail" :disabled="emailCopied" alt="Copy e-mail to clipboard">
                    <font-awesome-icon icon="fa-solid fa-copy" v-if="!emailCopied" />
                    <font-awesome-icon icon="fa-solid fa-check" v-if="emailCopied" />
                </button>
            </li>
            <li>
                <font-awesome-icon icon="fa-solid fa-paper-plane" />&nbsp;
                <a href="https://t.me/pi_berthet" target="_blank">{{ telegram }}</a>
            </li>
            
            </ul>
        </p>
        
        </div>
    </eye-folder>    
</template>
<script setup>    
    import { ref } from 'vue';
    import { useArcadeStore } from '@/store/index'; 
    import EyeFolder from '@/components/EyeFolder.vue';
    const store = useArcadeStore();    
    const email = "pierre-igor@sepafo.ch";
    const telegram = "pi_berthet";
    const emailCopied = ref(false);

    function copyEmail(){
        navigator.clipboard.writeText(email);
        emailCopied.value = true;
        setTimeout(() => {
            emailCopied.value = false;
        }, 3000);
    }
</script>
<style lang="scss" scoped>
    ul{
        list-style: none;
        margin: 0;
        padding: 0;
        text-align: left;
    }
</style>