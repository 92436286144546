<template>
    <div class="panel progress">
        <div :style="{width:(refined_value * 100) + '%'}"></div>
    </div>
</template>

<script setup>
import { defineProps, watch, ref, onMounted } from 'vue';
import * as THREE from 'three';

const props = defineProps(['value']);
const refined_value = ref(0);
const target_value = ref(0);

setInterval(() => {
    target_value.value = props.value;
    target_value.value = Math.min(target_value.value, 1);
    refined_value.value = THREE.MathUtils.lerp(refined_value.value, target_value.value, .1);
}, 10);

</script>
<style lang="scss" scoped>
    .progress div {
        width: 100%;
        max-width: 100%;
        background: white;
        box-sizing: border-box;
        height: 16px;
    }
</style>