<template>
    <eye-folder :style="store.project_position.style" @change="onToggleFolder">
    <div id="interface-project">        
            <div>
            
                <button class="mini-rounded panel" @click="store.prevProject">
                    <font-awesome-icon icon="fa-solid fa-arrow-left" />
                </button>
                <button class="panel mini-rounded" @click="store.nextProject">
                    <font-awesome-icon icon="fa-solid fa-arrow-right" />
                </button>
            </div>
            <div class ="panel" id="project-content" v-if="store.project != null">
                <p class="title">
                    {{ store.project.title }}    
                    <span class="project-year" v-if="store.project.year">
                        {{ store.project.year }}
                    </span>
                </p>
                
                <p class="project-description">
                    {{ store.project.short_text }}
                    <br/>
                    
                </p>

                <div class="project-choices">
                    <a class="button" :href="store.project.url" target="_blank" v-if="store.project.type == 'html'">Play now</a>
                    <a class="button" :href="store.project.url" target="_blank" v-if="store.project.p_windows">Download</a>
                </div>
            </div>
            
                
            
        
    </div>
    </eye-folder>
</template>
<script setup>
    import { watch } from 'vue';
    import { useArcadeStore } from '@/store/index';
    import EyeFolder from '@/components/EyeFolder.vue';
    const store = useArcadeStore();
    
    function onToggleFolder(state){
        store.toggleBeamer(state);
    }
    
</script>
<style lang="scss">
    #interface-project {        
        display: flex;        
        flex-direction: column;
        align-items: center;
        & > #project-content{                        
             width: 320px; 
        }
        
    }

    .project-title{
        text-align: left;
        font-weight: bold;
    }

    .project-year{
        float: right;
    }

    .project-description {
        font-size: 80%;
        text-align: left;
    }

    .project-choices{
        display:flex;        
        justify-content: space-between;
        button {
            margin-top:8px;
        }
    }
</style>