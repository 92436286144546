import { slotFlagsText } from '@vue/shared';
import * as THREE from 'three';
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
import { GlitchPass } from 'three/addons/postprocessing/GlitchPass.js';
import { BadTVShader } from './shaders/BadTVShader.js';

import { BootloaderCartridge } from './cartridges/bootloader';
import { InvaderCartridge } from './cartridges/invader';
import { ProjectsCartridge } from './cartridges/projects';


export class ArcadeScreen {

    
    screen_width = 320.0;
    screen_height = 240.0;

    render_target = null;

    bootloader = new BootloaderCartridge();

    time = 0;

    constructor(renderer){
        const container = document.getElementById("arcade-screen");                
        const camera = new THREE.OrthographicCamera(40.0 / -2, 40.0 / 2, 30.0 / 2, 30.0 / -2, 1, 1000);
        camera.position.z = 30;       

        const scene = new THREE.Scene();
        this.scene = scene;
        this.active_scene = scene;

        let width = this.screen_width;
        let height = this.screen_height;

        this.render_target = new THREE.WebGLRenderTarget(width, height, { generateMipmaps: false, minFilter: THREE.LinearFilter, magFilter: THREE.NearestFilter});        

        this.camera = camera;
        this.renderer = renderer;        
        
        
        const screen_pass = new RenderPass(this.active_scene, this.camera);
        //screen_pass.renderToScreen = true;     
        //screen_pass.needsSwap = true;

        const glitch_pass = new GlitchPass();
        const badtv_pass = new ShaderPass(BadTVShader);
        badtv_pass.uniforms['distortion'].value = 1.0;
        badtv_pass.uniforms['distortion2'].value = 10.0;
        badtv_pass.uniforms['speed'].value = .6;
        badtv_pass.uniforms['rollSpeed'].value = 0;
        this.badtv_pass = badtv_pass;
        const composer = new EffectComposer(this.renderer, this.render_target);
        composer.addPass(screen_pass);
        
        //composer.addPass(glitch_pass);
        composer.addPass(badtv_pass);
        composer.renderToScreen = false;
        this.composer = composer;        
        
        this.screen_pass = screen_pass;
        //this.insert_cartridge(new ProjectsCartridge());
        this.insert_cartridge(new InvaderCartridge());
        this.render();

        this.bootloader.addEventListener('load', this.bootloader_load);
    }

    bootloader_load = (event) => {
        this.insert_cartridge(event.message);
    }

    update = (delta, joystick) => {
        this.time += delta;

        if(this.cartridge != null){
            this.cartridge.update(delta, joystick);
        }        
      
        this.badtv_pass.uniforms['time'].value = this.time;
    }

    render = () => {                

        
        //this.renderer.setRenderTarget(this.render_target);        
        //this.renderer.render(this.active_scene, this.camera, this.render_target);        
        //this.renderer.setRenderTarget(null);              
        this.update_scene(); 
        this.composer.render();      
        //this.renderer.render(this.active_scene, this.camera);                
    }

    update_scene = () =>{
        if(this.cartridge != null){   
            this.active_scene = this.cartridge.scene;                
        } else {
            this.active_scene = this.scene;
            
        }
        this.screen_pass.scene = this.active_scene;
    }

    insert_cartridge = (cartridge) => {
        if(this.cartridge != null){
            this.cartridge.remove();
        }

        this.cartridge = cartridge;        
        this.cartridge.insert();
        this.update_scene();
    }
}