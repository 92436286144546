import { defineStore } from 'pinia'
import { InterfacePosition } from '@/assets/js/commons/interface_position'

export const useCounterStore = defineStore('counter', {
  state: () => ({ count: 0 }),
  getters: {
    double: state => state.count * 2,
  },
  actions: {
    increment() {
      this.count++
    },
  },
})

export const useArcadeStore = defineStore('arcade', {
  state: () => ({ count:0,state: 'init', 
                  loading_count: 0,                  
                  loading_value: 0,
                  cartridge:0, 
                  bootloader_available: false,
                  beamer_state: false, 
                  boombox_state: false,
                  project_index:0,
                  project:null,                   
                  project_position: new InterfacePosition({min_dot:.90}),
                  visit_card_position: new InterfacePosition({min_dot:.95}),
                  bootloader_position: new InterfacePosition({min_dot:.90}),
                  boombox_position: new InterfacePosition({min_dot:.95, min_distance: 5.0}),
                  orientation: {}
                }),
  getters: {
    double: state => state.count * 2,    
    loading: state => state.loading_value < 1.0
  },
  actions: {
    tickLoadCount(){
      this.loading_count++;            
      this.loading_value = this.loading_count / 4;      
    },
    prevProject(){

    },
    nextProject(){

    },
    toggleBeamer(value){
      this.beamer_state = value;
    },
    increment() {
      this.count++
    },
    toggleBoombox(value){
      this.boombox_state = value;
    },
    changeTrack(){

    },
    enterRoom(){
      
    }
  },
})