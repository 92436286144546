import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { useArcadeStore } from '@/store';

export class Boombox {

    surf_min = -.05;
    surf_max = .25;

    frequency_value = 0;

    constructor(scene, content){
        this.store = useArcadeStore();

        content.traverse(child => {
            if(child.name == "Boombox"){
                this.origin = child;              
                this.boombox_material = this.origin.children[0].material;  
                
                this.boombox_material.emissive = new THREE.Color('black');
            }

            if(child.name == "FrequencyIndicator"){
                this.frequency_indicator = child;
            }

            if(child.name == "BoomboxTuneButton"){
                this.tune_button = child;
            }
        });

    }

    update = (delta) => {
        if(this.frequency_indicator){
            this.frequency_indicator.position.x = THREE.MathUtils.lerp(this.surf_min, this.surf_max, this.frequency_value);            
        }

        if(this.tune_button){
            this.tune_button.rotation.x = Math.PI * this.frequency_value;
        }
    }

    turnOn = ()  => {
        this.boombox_material.emissive = new THREE.Color('white');
    }

    turnOff = ()  => {
        this.boombox_material.emissive = new THREE.Color('black');
    }

    toggle = (state) => {
        if(state){
            this.turnOn();            
        } else {
            this.turnOff();
        }
    }

    surf(){
        let tween;
        let tweens = [];
        let values = [];
        let diffs = [];

        for(var i = 0; i < 3; i ++){
            let value = Math.random() * .5;

            if(i % 2 == 0){
                value += .5;
            }

            values.push(value);
            
            if( i > 0){
                diffs.push(Math.abs(values[i] - values[i-1]))
            } else {
                diffs.push(Math.abs(values[i] - this.frequency_value))
            }

            tween = new TWEEN.Tween(this)
            .to({frequency_value: values[i]}, 1000 * diffs[i] + 100)
            .easing(TWEEN.Easing.Cubic.In);
            
            if(i > 0){
                tween.chain(tweens[i-1]);
            }
            tween.start();
            tweens.push(tween);
        }
        
        

        
    }
}