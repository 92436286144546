
import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';
import { useArcadeStore } from '@/store';
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons';

export class Beamer{

    time = 0;
    current_game_index = 0;

    slide_container_tween = null;

    should_show = false;
    should_show_time = 0;

    constructor(scene, content){

        this.scene = scene;
        this.createMaterial();
        this.store = useArcadeStore();

        content.traverse(child => {
            if(child.name == "Beamer"){
                this.beamer_object = child;
            }

            if(child.name == "BeamerScreen"){
                this.beamer_screen = child;                
            }

            if(child.name == "BeamerSurface"){
                this.beamer_surface = child;
                let texture = new THREE.TextureLoader().load( "textures/beamer_screen_e.png" );
                this.beamer_surface_material.uniforms.emissiveMap.value = texture;
                //this.beamer_surface_material = new THREE.MeshStandardMaterial({color: 0xFFFFFF, emissiveMap: texture, emissive: 0xFFFFFF});                
                this.beamer_surface.material = this.beamer_surface_material;
            }

            if(child.name == "BeamerPoint"){
                this.beamer_point = child;

                
            }

            if(child.name == "BeamerRay"){
                this.beamer_ray = child;
            }

            if(child.name == "BeamerSlideContainer"){
                this.slide_container = child;
            }
        });

        fetch('./data/games.json')
            .then(response => response.json())
            .then(data => {
                this.games = data.games;
                this.games = this.games.filter(game => 'cover_url' in game);                
                
                let texture = new THREE.TextureLoader().load( this.getImageUrlFromGame(this.games[0]));
                this.beamer_surface_material.uniforms.map.value = texture;
                
                this.showGame();
            });

        this.createLights();
        this.turnOff();
    }

    getImageUrlFromGame(game){
        return 'covers/' + game.id + '.png';
    }

    createMaterial(){
        this.beamer_surface_material = new THREE.ShaderMaterial({
            transparent: true,
            uniforms: {
                color: {type:"c", value: new THREE.Color(0x000000)},
                tint: {type:"c", value: new THREE.Color(0xFFFFAA)},
                map: {type:"t"},
                emissiveMap: {type:"t"},
            },
            vertexShader:`
                varying vec2 vUv;

                void main() {
                    vUv = uv;
                    vec4 modelViewPosition = modelViewMatrix * vec4(position, 1.0);
	                gl_Position = projectionMatrix * modelViewPosition;
                }
            `,
            fragmentShader:`
                varying vec2 vUv;

                uniform vec3 color;
                uniform vec3 tint;
                uniform sampler2D map;
                uniform sampler2D emissiveMap;
                
                void main(){      
                    vec2 uv = vUv;
                    uv.y *= 1.5;
                    uv.y -= .25;
                    vec4 emission = texture2D(emissiveMap, uv);
                    //gl_FragColor = mix(texture2D(map, uv), emission, .5) * emission.r * color.r;
                    vec4 contentColor = texture2D(map, uv);
                    //gl_FragColor = contentColor * emission;
                    gl_FragColor = contentColor * 1.2 * vec4(tint, 1.0);
                    //gl_FragColor.a = (gl_FragColor.r + gl_FragColor.g + gl_FragColor.b) / 3.0;
                    gl_FragColor.a = emission.r;
                    
                    gl_FragColor *= vec4(color.r); //filter
                }
            `
        })
    }

    createLights(){
        let light = new THREE.PointLight(0xFFFFFF, 2.0, 16.0);
        let position = new THREE.Vector3(0,0,0);                                
        this.beamer_point.getWorldPosition(position);        
        light.position.copy(position);        
        //this.scene.add(light);

        let ambient = new THREE.AmbientLight({color:0xAAAAAA});
        //this.scene.add(ambient);
    }

    update = (delta) => {
        this.time += delta;

        if(this.beamer_ray){
            this.beamer_ray.material.transparent = true;
            this.beamer_ray.material.map.wrapT = THREE.ClampToEdgeWrapping;
            this.beamer_ray.material.map.offset.y = Math.abs(Math.sin(this.time * 16.0)) * -.1;

        }

        if(this.should_show && this.time - this.should_show_time > .4){
            this.should_show = false;
            this.turnOn(50);
            this.showGame();
        }
    }

    get game() {
        return this.games[this.current_game_index];
    }

    displayGameInfo = () => {
        this.store.project = this.game;
    }

    showGame = () => {
        let texture = new THREE.TextureLoader().load( this.getImageUrlFromGame(this.game));
        texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
        this.beamer_surface_material.uniforms.map.value = texture; 
        this.displayGameInfo();
    }


    rotateSlides = (delta) => {
        if(this.slide_container_tween != null){
            this.slide_container_tween.stop();
        }
        
        let target_value = this.slide_container.rotation.x + delta;

        this.slide_container_tween = new TWEEN.Tween(this.slide_container.rotation)
        .to({
            x:target_value
        }, 250)        
        .easing(TWEEN.Easing.Back.In)
        .start();
    }

    nextGame = () => {
        this.current_game_index += 1;
        if(this.current_game_index >= this.games.length){
            this.current_game_index = 0;
        }
        
        this.turnOff(50);
        this.should_show = true;
        this.should_show_time = this.time;
        this.rotateSlides(Math.PI / 18);
        this.displayGameInfo();
    }

    prevGame = () => {
        
        this.current_game_index -= 1;
        if(this.current_game_index < 0){
            this.current_game_index = this.games.length - 1;
        }
        
        this.turnOff(50);
        this.should_show = true;
        this.should_show_time = this.time;
        this.rotateSlides(-Math.PI / 18);
        this.displayGameInfo();
    }

    toggleBeamer = (state) => {
        if(state == true){
            this.turnOn();
            this.displayGameInfo();
        } else {
            this.turnOff();
        }
    }

    turnOn = (duration = 300) => {
        new TWEEN.Tween(this.beamer_surface.material.uniforms.color.value)
        .to({r:1.0, g:1.0,b:1.0}, duration)
        .start()
        
        new TWEEN.Tween(this.beamer_ray.scale)
        .to({x:1.0, y:1.0,z:1.0}, duration)
        .start()
    }

    turnOff = (duration = 300) => {
                
        new TWEEN.Tween(this.beamer_surface.material.uniforms.color.value)
        .to({r:0.0, g:0.0,b:0.0}, duration)
        .start()

        new TWEEN.Tween(this.beamer_ray.scale)
        .to({x:0.0, y:0.0,z:0.0}, duration)
        .start()
    }
}