import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from 'pinia'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faUserSecret, faEye, faXmark, faArrowRight, faArrowLeft, faPaperPlane, faPhone, faEnvelope, faAt, faCopy, faCheck } from '@fortawesome/free-solid-svg-icons'

library.add(faUserSecret, faEye, faXmark, faArrowRight, faArrowLeft, faPaperPlane, faPhone, faEnvelope, faAt, faCopy, faCheck);

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(createPinia())
.mount('#app')
