<template>
    <div class="eye-folder-container">        
    <button class="rounded panel" @click="toggleOpened" v-if="!opened">
        <font-awesome-icon icon="fa-solid fa-eye" />
    </button>
    
    <slot v-if="opened"></slot>
    <button class="close-folder mini-rounded panel" @click="toggleOpened"  v-if="opened">
        <font-awesome-icon icon="fa-solid fa-xmark" />
    </button>
    </div>
</template>
<script setup>
    import { defineEmits, ref } from 'vue';

    const emit = defineEmits(['change']);

    const opened = ref(false);

    function toggleOpened(){
        opened.value = !opened.value;
        emit('change', opened.value);
    }
</script>
<style lang="scss">
     .eye-folder-container{
         position: absolute;
     }

     .close-folder{
         
         position: absolute;
         right: -16px;
         top: -16px;
     }
</style>