import * as THREE from 'three';
import { useArcadeStore } from '@/store';

export class Rig {

    move_speed = 8.0;
    mouse_x = 0;
    mouse_y = 0;
    
    orientation = new THREE.Vector2(0,0);

    constructor(camera){
        this.store = useArcadeStore();
        this.camera = camera;
        this.pivot = new THREE.Object3D();
        this.offset = new THREE.Object3D();
        this.pivot.add(this.offset);
        this.offset.add(camera);

        this.target_rotation = new THREE.Vector3();

        window.addEventListener('mousemove', this.mousemove)
        //window.addEventListener("deviceorientation", this.handleOrientation);
        //window.addEventListener("devicemotion", this.handleMotion);        
        
    }

    mousemove = (event) => {
        this.mouse_x = ((event.clientX / window.innerWidth) * 2.0) - 1.0;
        this.mouse_y = ((event.clientY / window.innerHeight) * 2.0) - 1.0;
        this.updateTargetRotation(this.mouse_x, this.mouse_y);
    }

    touchmove = (event) => {
        this.mouse_x = ((event.touches[0].clientX / window.innerWidth) * 2.0) - 1.0;
        this.mouse_y = ((event.touches[0].clientY / window.innerHeight) * 2.0) - 1.0;
        this.updateTargetRotation(this.mouse_x, this.mouse_y);
    }

    handleOrientation = (event) => {
        //const absolute = event.absolute;
        const alpha    = event.alpha;
        const beta     = event.beta;
        const gamma    = event.gamma;
        
        this.mouse_x = - gamma / 45.0;
        this.mouse_y = - beta / 180.0 + (Math.PI / 4);

        //this.store.orientation = {alpha: alpha, beta: beta, gamma: gamma};
        
        
        //this.updateTargetRotation(this.orientation.x, this.orientation.y);
    }

    handleMotion = (event) => {
        /*
        if(Math.abs(event.acceleration.z) > .1){
            this.orientation.y += (event.acceleration.z * .00125);
            this.orientation.y = THREE.MathUtils.clamp(this.orientation.y, -1.0, 1.0);
        }
        */ 

        if(Math.abs(event.acceleration.x) > .1){
            this.orientation.x += (event.acceleration.x * .00125);
            this.orientation.x = THREE.MathUtils.clamp(this.orientation.x, -1.0, 1.0);
        }
        
        this.updateTargetRotation(this.orientation.x, this.orientation.y);
    }

    updateTargetRotation = (x, y) => {
        this.target_rotation.y = THREE.MathUtils.lerp(0, - Math.PI / 12, x);
        this.target_rotation.x = THREE.MathUtils.lerp(0, - Math.PI / 6, y);
    }

    update = (delta) => {
        let step = THREE.MathUtils.clamp(delta * this.move_speed, 0, 1.0);
        this.offset.rotation.y = THREE.MathUtils.lerp(this.offset.rotation.y, this.target_rotation.y, step);
        this.camera.rotation.x = THREE.MathUtils.lerp(this.camera.rotation.x, this.target_rotation.x, step);
    }
}