import * as THREE from 'three';

export class ArcadeJoystick {
    
    pressed_angle = - Math.PI / 6.0;

    joystick_rotation = new THREE.Vector3(0.0, 0.0, 0.0);

    move_vector = new THREE.Vector2(0,0);

    buttons = [];

    constructor(scene){
        scene.traverse((child) => {
            if(child.name == 'Joystick'){
                this.joystick = child;
                window.addEventListener('keydown', this.keydown);
                window.addEventListener('keyup', this.keyup);
            }

            if(child.name == 'Button1'){
                this.button1 = new JoystickButton(child, [32, 81]);
                this.buttons.push(this.button1);
            }


            if(child.name == 'Button2'){
                this.button2 = new JoystickButton(child, [69]);
                this.buttons.push(this.button2);
            }
        });
        
    }

    keydown = (e) => {
        e = e || window.event;
        

        if (e.keyCode == '38' || e.key == 'w') {            
            this.move_vector.y = 1.0;
        }
        else if (e.keyCode == '40' || e.key == 's') {            
            this.move_vector.y = -1.0;
        }
        else if (e.keyCode == '37' || e.key == 'a') {            
            this.move_vector.x = -1.0;
        }
        else if (e.keyCode == '39' || e.key == 'd') {            
            this.move_vector.x = 1.0;
        }        

        this.buttons.forEach(button => {
            button.check_down(e);
        });
    }

    keyup = (e) => {        

        if (e.keyCode == '38' || e.key == 'w') {            
            this.move_vector.y = 0.0;
        }
        else if (e.keyCode == '40' || e.key == 's') {            
            this.move_vector.y = 0.0;
        }
        else if (e.keyCode == '37' || e.key == 'a') {            
            this.move_vector.x = 0.0;
        }
        else if (e.keyCode == '39' || e.key == 'd') {            
            this.move_vector.x = 0.0;
        }
        

        this.buttons.forEach(button => {
            button.check_up(e);
        });
    }

    

    update = (delta) => {
        this.joystick.rotation.set(this.move_vector.y * this.pressed_angle, 0, this.move_vector.x * this.pressed_angle);
        
        this.buttons.forEach(button => {
            button.update(delta);
        });
    }
}

class JoystickButton {
    pressed = false;
    was_pressed = false;

    codes = [];

    constructor(object, codes){
        this.object = object;
        this.codes = codes;
    }   

    update = (delta) => {
        this.object.position.y = this.pressed ? -.005 : 0; 
    }

    check_down = (event) => {
        if(this.codes.includes(event.keyCode)){
            this.pressed = true;
            this.was_pressed = true;
        }
    }

    check_up = (event) => {
        if(this.codes.includes(event.keyCode)){
            this.pressed = false;
            this.was_pressed = false;

        }
    }

    is_just_pressed = () => {
        if(this.was_pressed){
            this.was_pressed = false;
            return true;
        }
        return false;
    }
}