import * as THREE from 'three';
import { Cartridge } from './commons/cartridge';

export class SnakeCartridge extends Cartridge {
    world_boundaries = new THREE.Vector2(16.0, 10.0);
    snake_direction = new THREE.Vector3(0, 1, 0);
    parts = [];
    snake_length = 8;
    time = 0;
    move_rate = .25;
    move_time = 0;
    snake_position = new THREE.Vector3(0,0,0);
    positions = [];
    fruit = null;
    gameover = false;

   constructor(){
       super();
              
       this.create_gameover_scene();
       this.initialize();
   }

   initialize = () => {        
        this.gameover = false;
        this.game_scene = new THREE.Scene();
        this.scene = this.game_scene;
        this.origin = new THREE.Object3D();
        this.game_scene.add(this.origin);
        this.snake_direction = new THREE.Vector3(0, 1, 0);
        this.snake_position = new THREE.Vector3(0,0,0);
        this.snake_length = 8;
        this.parts = [];

        for(var i = 0; i < this.snake_length; i ++)    {
            let position = new THREE.Vector3(0, -i, 0);
            this.positions.push(position);
            this.add_parts();                                    
        }
        
        this.place_fruit();
   }

   reset = () => {
       this.move_rate = .25;
   }

   place_fruit = () => {
       if(this.fruit == null){
            const geometry = new THREE.BoxGeometry( 1, 1, 1 );
            const material = new THREE.MeshBasicMaterial( {color: 0xff0000} );
            const cube = new THREE.Mesh( geometry, material );                        
            this.fruit = cube;
            this.origin.add( cube );
       }

       this.fruit.position.set(Math.round(Math.random() * 2.0 - 1.0) * this.world_boundaries.x, Math.round(Math.random() * 2.0 - 1.0) * this.world_boundaries.y, 0);
   }

   add_parts = () => {
        const geometry = new THREE.BoxGeometry( 1, 1, 1 );
        const material = new THREE.MeshBasicMaterial( {color: new THREE.Color(0, 1.0, this.parts.length / 16.0)} );
        const part = new THREE.Mesh( geometry, material );
        //part.position.copy(position);
        let position = this.positions[this.positions.length - 1 - this.parts.length];
        
        this.origin.add( part );
        part.position.copy(position);
        this.parts.push(part);
        return part;
   }

   update = (delta, joystick) => {
        this.time += delta;


        if(this.scene == this.game_scene){
            this.move_time += delta;

            if(Math.abs(joystick.move_vector.x) > Math.abs(joystick.move_vector.y) && this.snake_direction.x == 0){
                this.snake_direction.x = Math.sign(joystick.move_vector.x);
                this.snake_direction.y = 0;
            } else if (Math.abs(joystick.move_vector.y) > Math.abs(joystick.move_vector.x) != 0 && this.snake_direction.y == 0) {
                this.snake_direction.y = Math.sign(joystick.move_vector.y);
                this.snake_direction.x = 0;
            }

            if(this.move_time >= this.move_rate){
                this.move_time = 0;
                this.move_snake();
            }
        }
        
   }

   remove = () => {
       //this.scene.remove(this.origin);
   }

   move_snake = () => {

    this.snake_position.add(this.snake_direction);

    if(this.snake_position.x > this.world_boundaries.x){
        this.snake_position.x = - this.world_boundaries.x;
    }

    if(this.snake_position.x < - this.world_boundaries.x){
        this.snake_position.x = this.world_boundaries.x;
    }


    if(this.snake_position.y > this.world_boundaries.y){
        this.snake_position.y = - this.world_boundaries.y;
    }

    if(this.snake_position.y < - this.world_boundaries.y){
        this.snake_position.y = this.world_boundaries.y;
    }

    this.positions.push(this.snake_position.clone());    

    this.update_parts();

    this.check_collisions();
   }

   check_collisions = () => {
        if(this.snake_position.distanceTo(this.fruit.position) < .5){
            this.place_fruit();
            this.add_parts();
            this.move_rate /= 1.05;
        }

        for(var i = 1; i < this.parts.length; i ++){
            if(this.parts[0].position.equals(this.parts[i].position)){
                this.do_gameover();
            }
        }
   }

   update_parts = () => {
        if(this.positions.length < this.parts.length){
            return;
        }

        for(var i in this.parts){
            this.parts[i].position.copy(this.positions[this.positions.length - i - 1]);
        }
   }

   generate_sprite = (col, row) => {
    let texture = new THREE.TextureLoader().load( "/textures/texts.png" );
    texture.repeat.set(.25, .25);
    texture.offset.set(.25 * col, .25 * row);        
    
    
    const text_material = new THREE.SpriteMaterial({color: 0x00ff00, map: texture, sizeAttenuation: false});
    const text = new THREE.Sprite(text_material);
    let scale = 48.0;
    text.scale.set(scale, scale, scale);

    return text;
    }

   create_gameover_scene = () => {
    const gameover_scene = new THREE.Scene(); 
    const gameover_text = this.generate_sprite(2, 0);
    gameover_scene.add(gameover_text);
    
    

            
    this.gameover_scene = gameover_scene;
    }

    do_reset = () => {
        this.initialize();
    }

    do_gameover = () => {
        if(this.gameover)
        {
            return;
        }
 
        this.gameover = true;
        this.scene = this.gameover_scene;       
        this.flash();
 
        setTimeout(() => {
         this.do_reset();         
        }, 3000);
    }
}