
import { Cartridge } from "./commons/cartridge";

import { InvaderCartridge } from './invader';
import { ProjectsCartridge } from './projects';
import { SnakeCartridge } from "./snake";


export class BootloaderCartridge extends Cartridge {

    cartridges = [];

    constructor(){
        super();
        this.initialize();
    }

    initialize = () => {
        this.cartridges.push(new ProjectsCartridge());
        this.cartridges.push(new InvaderCartridge());
        this.cartridges.push(new SnakeCartridge());
        window.addEventListener('keydown', this.keydown)
    }

    update = (delta, joystick) => {

    }

    keydown = (event) => {
        
            for (var i = 1; i <= this.cartridges.length; i++){
                if (event.code == "Digit" + i){
                    this.dispatchEvent({type:"load", message: this.cartridges[i-1]});    
                }
            }                
    }
}