import * as THREE from 'three';
import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';

import { Cartridge } from "./commons/cartridge";

import { useArcadeStore } from '@/store';

export class ProjectsCartridge extends Cartridge {

    tile_size = 32.0;

    time = 0;
    last_move_time = 0;

    current_game_index = 0;

    url_was_opened = false;

    constructor(){
        super();
        this.initialize();      
        this.store = useArcadeStore();  
    }

    initialize = () => {        
        this.scene_color = new THREE.Color(0,0,0);

        fetch('./data/games.json')
            .then(response => response.json())
            .then(data => {
                this.games = data.games;
                this.games = this.games.filter(game => 'cover_url' in game);
                  this.generate_posters();                
            });
        
    }

    flash = () => {
        new TWEEN.Tween(this.scene_color)
        .to(new THREE.Color('white'), 100)
        .start();

        new TWEEN.Tween(this.scene_color)
        .to(new THREE.Color(.2, .2, .2), 250)
        .delay(100)
        .start();
   }
    
    generate_posters = () => {

        this.pivot = new THREE.Object3D();
        this.scene.add(this.pivot);

        this.games.forEach((game, index) => {
            const texture = new THREE.TextureLoader().load(this.getImageUrlFromGame(game));    
            const material = new THREE.SpriteMaterial({map:texture});
            const sprite = new THREE.Sprite(material);            
            sprite.scale.set(1.26 * this.tile_size, this.tile_size);
            sprite.position.y = index * -this.tile_size;
            this.pivot.add(sprite);
        });
    }

    display = (game) =>{
        const texture = new THREE.TextureLoader().load(this.getImageUrlFromGame(game));
        
        
    }

    update = (delta, joystick) => {
        this.time += delta;

        if(joystick.move_vector.y != 0 && this.time - this.last_move_time > .5){
            this.last_move_time = this.time;
            this.switch_project(this.current_game_index - joystick.move_vector.y);            
        }       
        
        
        

        if(joystick.button2.is_just_pressed() && !this.url_was_opened){
            setTimeout(() => {                
                window.open(this.games[this.current_game_index].url, '_blank').focus();
            }, 200)            
            
        }
        

        this.pivot.position.y = this.current_game_index * this.tile_size;
        this.scene.background = this.scene_color;
    }

    switch_project = (index) => {
        this.current_game_index = index;
        this.current_game_index = THREE.MathUtils.clamp(this.current_game_index, 0, this.games.length);                
        this.dispatchEvent('project_change', this.games[this.current_game_index]);
        this.flash();
    }

    insert = () => {
        this.switch_project(this.current_game_index);
    }

    remove = () => {
        
    }

    getImageUrlFromGame(game){
        return 'covers/' + game.id + '.png';
    }
}