<template>
    <eye-folder @change="onEyeFolderChange" :style="store.boombox_position.style">
    <div id="interface-boombox" class="panel">
        <button @click="toggleState">{{ isOn ? "Turn off" : "Turn on" }}  </button>
        <button @click="nextTrack" v-if="isOn">Change station</button>
    </div>
    </eye-folder>
    <div ref="tracksContainer">
    <audio ref="mainTrack" loop="true" preload="auto">
        <source src="audios/boombox/ggj_2021_main_track.ogg"/>
    </audio>    
    <audio loop="true" preload="metadata">
        <source src="audios/boombox/botchers_title.mp3"/>
    </audio>
    <audio loop="true" preload="metadata">
        <source src="audios/boombox/botchers_trailer.mp3"/>
    </audio>
    <audio loop="true" preload="metadata">
        <source src="audios/boombox/botchers_track_01.mp3"/>
    </audio>
    <audio loop="true" preload="metadata">
        <source src="audios/boombox/botchers_track_02.mp3"/>
    </audio>
    <audio loop="true" preload="metadata">
        <source src="audios/boombox/secret_maze.mp3"/>
    </audio>
    <audio loop="true" preload="metadata">
        <source src="audios/boombox/Swan Lake Op.20.mp3"/>
    </audio>
    <!--<audio src="http://shoutcast.internet-radio.org.uk:10272/;" loop="true"></audio>-->
    </div>
</template>
<script setup>
    import { ref, onMounted } from 'vue';
    import EyeFolder from '@/components/EyeFolder.vue';
    import { useArcadeStore } from '@/store';
    import { TWEEN } from 'three/examples/jsm/libs/tween.module.min';

    const store = useArcadeStore();

    let audioContext = null;
    let audioBuffer;
    let audioSource = null;
    
    const mainTrack = ref(null);
    const tracksContainer = ref(null);

    const tracks = [];
    let current_track_index = 0;

    let isOn = ref(false);

    onMounted(() => {           
        for (const child of tracksContainer.value.children){            
            tracks.push(child);            
        }
        
    })
    
    store.$onAction(action => {
        switch(action.name){
            case "enterRoom":
                generateFilters();
            break;            
    }
    });

    function generateFilters(){
        for(const child of tracks){
            applyFilter(child);
        }
    }

    function applyFilter(audioElement){
            if(audioContext == null){
            audioContext = new (window.AudioContext || window.webkitAudioContext)()
            
            }

            const highpassFilter = audioContext.createBiquadFilter();
            highpassFilter.frequency.value = 400;            
            highpassFilter.type ="highpass";
            highpassFilter.connect(audioContext.destination);

            const allpassFilter = audioContext.createBiquadFilter();
            allpassFilter.frequency.value = 6000;            
            allpassFilter.Q.value = 0;            
            allpassFilter.type ="bandpass";
            allpassFilter.connect(highpassFilter);

            if(audioSource == null){
                audioSource = audioContext.createMediaElementSource(audioElement);
                audioSource.connect(audioContext.destination);
                audioSource.connect(allpassFilter);
            }
    }

   function onEyeFolderChange(state){
              
   }

    function toggleState(){
        isOn.value = !isOn.value;
        let currentTrack = tracks[current_track_index];
        if(isOn.value){           
            store.toggleBoombox(true);
           playTrack(currentTrack);
           
       } else {
           store.toggleBoombox(false);
           stopTrack(currentTrack);
           
       }
    }

    function playTrack(track){
        let date = new Date();        
        if(!isNaN(track.duration)){
            track.currentTime = (date.getTime() / 1000) % track.duration;
        }

           if(track.paused){
               track.volume = 0;
               track.play();
           }
           
           new TWEEN.Tween(track)
           .to({volume:1}, 500)
           .start();
    }

    function stopTrack(track){
        new TWEEN.Tween(track)
           .to({volume:0}, 500)
           .start();
    }

   function nextTrack(){
       let previousTrack = tracks[current_track_index];
       stopTrack(previousTrack);
       current_track_index += 1;
       if(current_track_index >= tracks.length){
           current_track_index = 0;
       }

       let currentTrack = tracks[current_track_index];
       playTrack(currentTrack);
       store.changeTrack();
   }

   function prevTrack(){

   }
</script>