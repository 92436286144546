import * as THREE from 'three';

export class InterfacePosition {
    default_config = {min_dot: .9, min_distance: 4.0};

    constructor(config){        
        this.config = config;
        if(this.config == null){
            this.config = {};
        }
        
        this.config = {...this.default_config, ...this.config};
                        
        this.position = new THREE.Vector3(0,0,0);
        this.scale = 0.0;
        this.camera_world_position = new THREE.Vector3(0,0,0);
        this.camera_world_direction = new THREE.Vector3();
        this.object_direction = new THREE.Vector3();
        window.addEventListener('mousemove', this.mouvemove);
    }

    update (position, camera, renderer) {        

        let project_position = new THREE.Vector3(4,0,-4);        
            
        project_position.copy(position);
        project_position.project(camera);
        project_position.x = (project_position.x * .5 + .5) * renderer.domElement.clientWidth;
        project_position.y = (project_position.y * -.5 + .5) * renderer.domElement.clientHeight;                
        this.position.copy(project_position);
        

        camera.getWorldDirection(this.camera_world_direction);
        camera.getWorldPosition(this.camera_world_position);
        
        this.object_direction.copy(position);
        
        this.object_direction.sub(this.camera_world_position);
        this.object_direction.normalize();
        let dot = this.object_direction.dot(this.camera_world_direction);
        let camera_distance = this.camera_world_position.distanceTo(position);
        this.distance = camera_distance;

        this.scale = (dot >= this.config.min_dot && camera_distance <= this.config.min_distance) ? 1.0 : 0.0;
        
        //this.scale = (1.0 - Math.max(0, this.camera_world_position.distanceTo(position)- 3.0) / 5.0);        

        this.scale = THREE.MathUtils.clamp(this.scale, 0.0, 1.0);

        this.left = Math.round(this.position.x);
        this.top = Math.round(this.position.y);
    }

    get style(){
        return {
            left: this.left + 'px',
            top: this.top + 'px',
            'transform-origin': 'center center',
            transform: 'scale(' + this.scale + ')'
        }
    }

    mouvemove = (event) => {

    }
}