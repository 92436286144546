<template>    
    <div id="interactive-scene"></div>            
    <!--
    <div class="panel" id="debug-panel">
        <button  @click="switchGround">boopity</button>
    </div>
    -->
</template>


<script setup>
import { ref, watch, defineProps, onMounted, onUnmounted } from 'vue'
import { useArcadeStore } from '@/store/index';

import { Arcade } from '../assets/js/arcade';

const props = defineProps({
    state: String
});

const store = useArcadeStore();

let arcade;
let arcade_machine;

onMounted(() => {
    arcade = new Arcade();    
    arcade_machine = arcade.world.arcade_machine;

    watch(() => store.state, (newValue, oldValue) => {
        arcade.changeState(newValue);        
    });

    watch(() => store.cartridge, (newValue, oldValue) => {
        arcade_machine.insert_cartridge(arcade_machine.bootloader.cartridges[newValue]);
    });

    store.$onAction(action => {
        switch(action.name){
            case "prevProject":
                arcade.world.beamer.prevGame();
            break;
            case "nextProject":
                arcade.world.beamer.nextGame();
            break;
            
            
        }
        action.after((result) => {
            switch(action.name){
                case "toggleBeamer":
                    arcade.world.beamer.toggleBeamer(store.beamer_state);
                    break;
                case "toggleBoombox":
                    arcade.world.boombox.toggle(store.boombox_state);
                    break;
                case "changeTrack":
                    arcade.world.boombox.surf();
                    break;
            }
        });

    });
    
});

function switchGround(){    
    arcade.world.switchGround();
}

onUnmounted(() => {
    arcade.kill();
});

</script>
<style lang="scss">
    #interactive-scene{

        width: 100%;        
        height: 100vh;
    }

    #screen{
        background:red;
        color: darkorchid;
        font-size:32px;
    }

    #arcade-screen{
        position:fixed;
        background: blue;
        width:320px;
        height:240px;
        top:0;
        left:0;
        
    }

    #debug-panel{
        position:absolute;
        left:0;
        top:50%;
        width:120px;
        height:120px;
        z-index:9999;
    }

</style>